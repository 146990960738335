/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

// for ie 11 -support---
import {eventBus} from "./event-bus/event-bus";
import 'es6-promise/auto';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import '@babel/polyfill'
import 'intersection-observer'
import cssVars from 'css-vars-ponyfill';

import Vue from 'vue'
import VueRouter from 'vue-router'
import AppContainer from "./components/App";
import routes from "./router/router";
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import {BootstrapVue} from 'bootstrap-vue'
import VueI18n from 'vue-i18n';
import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import vMultiselectListbox from 'vue-multiselect-listbox'
import '../css/app.css';
//VERSIONNUMBER
import version from '../version.json';

//TRANSLATIONS---
import en from '../lang/en.json';
import fr from '../lang/fr.json';

require('es6-promise').polyfill();

cssVars({
    include: 'style',
    onlyLegacy: true,
    watch: true,
});

Vue.use(BootstrapVue);
Vue.use(VueRouter);
// Vue.use(VueI18n);

//DateTimepicker
Vue.use(datePicker);


var router = new VueRouter(routes);

require('./bootstrap');
window.Vue = require('vue');
Vue.use(require('vue-moment'));

Vue.component('app', require('./components/App').default);
Vue.component('page-not-found', require('./components/PageNotFound').default);

Vue.component('app-footer', require('./components/layout/AppFooter').default);
Vue.component('app-header', require('./components/layout/AppHeader').default);
Vue.component('index-logged-in', require('./components/content/IndexLoggedIn').default);
Vue.component('index-logged-out', require('./components/content/IndexLoggedOut').default);
Vue.component('app-menu', require('./components/layout/AppMenu').default);
Vue.component('welcome', require('./components/layout/Welcome').default);

Vue.component('annual-accreditation', require('./components/content/AnnualAccreditation').default);
Vue.component('event-attendance', require('./components/content/EventAttendance').default);
Vue.component('forgot-password', require('./components/content/ForgotPassword').default);
Vue.component('login', require('./components/content/Login').default);
Vue.component('personal-data', require('./components/content/PersonalData').default);
Vue.component('register', require('./components/content/Register').default);

Vue.component('date-time-picker', require('./components/elements/DateTimePicker').default);
Vue.component('file-handler', require('./components/elements/FileHandler').default);

//---Extern --------------
Vue.component('v-multiselect-listbox', vMultiselectListbox)

// must be name as 'messages'
const messages = {
    en: en,
    fr: fr
}
// Create VueI18n instance with options
const i18n = new VueI18n({
    locale: 'en', // set locale
    fallbackLocale: 'en',
    messages, // set locale messages
})

// GLOBAL METHODS
Vue.mixin({
    data() {
        return {}
    },
    methods: {
        getVersion() {
            return version.version;
        },
        showSuccessToast(msg, hideDelay) {
            if (!hideDelay) {
                hideDelay = 1500;
            }
            this.$bvToast.toast(msg, {
                autoHideDelay: hideDelay,
                noCloseButton: true,
                toastClass: 'success-toast'
            });
        },
        showErrorToast(msg, hideDelay) {
            if (!hideDelay) {
                hideDelay = 1500;
            }
            this.$bvToast.toast(msg, {
                autoHideDelay: hideDelay,
                noCloseButton: true,
                toastClass: 'failure-toast'
            });
        },
        showDeleteConfirm(msg) {
            return this.showConfirm(this.$t('basics.please-confirm'), msg, 'danger');
        },
        showConfirm(title, msg, variant) {
            return this.$bvModal.msgBoxConfirm(msg, {
                title: title,
                size: 'sm',
                buttonSize: 'sm',
                okVariant: variant,
                okTitle: this.$t('basics.yes'),
                cancelTitle: this.$t('basics.no'),
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
        },
        setAuthentication(token) {
            if (token) {
                eventBus.$emit('auth', true);
                this.setAuthTokenToAxiosHeader(token);
                axios.get('isTokenValid');
            } else {
                eventBus.$emit('auth', false);
                this.setAuthTokenToAxiosHeader(token);
            }
        },
        setAuthTokenToAxiosHeader(token) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        },
        onLogout() {
            this.showLoadingApp(true);
            axios.post("/logout", {}).then(response => {
                this.showLoadingApp(false);
                localStorage.removeItem('user_access_token');
                localStorage.removeItem('user');
                this.setAuthentication('');
                this.$router.push('/');
            }).catch(error => {
                this.showLoadingApp(false);
            })
        },
        showLoadingApp(show) {
            if (show) {
                setTimeout(() => {
                    this.$bvModal.show("loading-modal");
                }, 10)
            } else {
                setTimeout(() => {
                    this.$bvModal.hide("loading-modal");
                }, 10)
            }
        },
        sortCountries(countries) {
            if (countries) {
                countries.sort((a, b) => {
                    if (a && b) {

                        if (localStorage.getItem("language") == 'fr') {
                            if (a.CountryName_FR < b.CountryName_FR) {
                                return -1;
                            }
                            if (a.CountryName_FR > b.CountryName_FR) {
                                return 1;
                            }
                        }

                        if (a.CountryName_EN < b.CountryName_EN) {
                            return -1;
                        }
                        if (a.CountryName_EN > b.CountryName_EN) {
                            return 1;
                        }

                    }
                    return 0;
                })
            }
        },
        formatToDate(value) {
            if (value) {
                if (this.$i18n.locale == 'en') {
                    return this.$moment(String(value)).format('MMMM DD, YYYY')
                } else {
                    return this.$moment(String(value)).format('DD MMMM YYYY')
                }
            }
        },
        formatToDateTime(value) {
            if (value) {
                if (this.$i18n.locale == 'en') {
                    return this.$moment(String(value)).format('MMMM DD, YYYY HH:mm')
                } else {
                    return this.$moment(String(value)).format('DD MMMM YYYY HH:mm')
                }
            }
        },
        getAnnualStateText(state) {
            if (!state || state === '0') {
                return this.$t('annual.state-draft');
            }
            if (state === '5') {
                return this.$t('annual.state-accepted');
            }
            if (state === '99') {
                return this.$t('annual.state-rejected');
            }

            return this.$t('annual.state-processed');
        },
        getAnnualStateColor(state) {
            if (!state || state === '0') {
                return 'var(--nato-blue-light)';
            }
            if (state === '5') {
                return 'var(--default-green)';
            }
            if (state === '99') {
                return 'var(--default-red)';
            }

            return 'var(--default-orange)';
        }

    },
})

const app = new Vue({
    el: '#app',
    components: {AppContainer},
    router,
    i18n
});

export {router};
export {i18n};
