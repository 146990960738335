<template>
    <div>
        <b-form class="" @submit.prevent="onLogin">

            <h1>{{ $t('login.login-heading') }}</h1>
            <span>{{ $t('login.login-heading-msg') }}</span>
            <hr>

            <div class="form-section">
                <div class="icon-section">
                    <i class="fas fa-lock"></i>
                </div>
                <b-input v-model="username" :placeholder="$t('login.email')" required size="sm"
                         type="text"></b-input>
                <b-input v-model="password" :placeholder="$t('login.password')" required size="sm"
                         type="password"></b-input>
                <b-button class="nato-default-btn" size="sm" type="submit" value="submit">{{ $t('login.login') }} <i
                    class="fas fa-sign-in-alt"></i></b-button>
            </div>
        </b-form>
    </div>
</template>

<script>

export default {
    name: "Login",
    data() {
        return {
            username: "",
            password: "",
        }
    },
    methods: {
        onLogin() {
            this.showLoadingApp(true);
            axios.post("/login", {
                'EMail': this.username,
                'Password': this.password
            }).then(response => {
                this.showLoadingApp(false);
                const token = response.data.data.access_token;
                const email = response.data.data.email;
                const firstname = response.data.data.firstname;
                const lastname = response.data.data.lastname;
                localStorage.setItem('user_access_token', token);
                this.setAuthentication(token);

                var userStore = {
                    "email": email,
                    "firstname": firstname,
                    "lastname": lastname,
                }

                localStorage.setItem('user', JSON.stringify(userStore));

                // if (language == 'fr') {
                //     i18n.locale = 'fr';
                //     localStorage.setItem("language", "fr");
                // } else {
                //     i18n.locale = 'en';
                //     localStorage.setItem("language", "en");
                // }

                this.$router.push('/home');
            }).catch(response => {
                this.showLoadingApp(false);
                localStorage.removeItem('user_access_token');
                localStorage.removeItem('user');
                this.showErrorToast(this.$t('login.invalidCredentials'));
            });
        },
    }
}
</script>

<style scoped>

.form-section {
    margin: 30px auto;
    max-width: 500px;
    border: 2px solid #eaeaea;
    padding: 20px;
}

.form-section input {
    margin-bottom: 10px;
}

.icon-section {
    text-align: center;
    font-size: 60px;
    color: #CBCBCB;
}

</style>
