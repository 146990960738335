import PageNotFound from "../components/PageNotFound";
import Login from "../components/content/Login";
import EventAttendance from "../components/content/EventAttendance";
import Register from "../components/content/Register";
import ForgotPassword from "../components/content/ForgotPassword";
import PersonalData from "../components/content/PersonalData";
import AnnualAccreditation from "../components/content/AnnualAccreditation";
import IndexLoggedOut from "../components/content/IndexLoggedOut";
import Home from "../components/layout/Home";
import IndexLoggedIn from "../components/content/IndexLoggedIn";
import Events from "../components/content/Events";
import PasswordReset from "../components/content/PasswordReset";
import AnnualAccreditationRequest from "../components/content/AnnualAccreditationRequest";

const ifAuthenticated = (to, from, next) => {
    const token = localStorage.getItem('user_access_token');
    if (token) {
        setAuthTokenToAxiosHeader(token);
        next();
        return;
    }
    next('/login');
}

const ifNotAuthenticated = (to, from, next) => {
    const token = localStorage.getItem('user_access_token');
    if (!token) {
        next();
        return;
    }
    setAuthTokenToAxiosHeader(token);
    next('/home');
}


const routes = {
    mode: 'history',
    routes: [
        {
            path: '/',
            component: IndexLoggedOut,
            beforeEnter: ifNotAuthenticated,
        },
        {
            path: '/register',
            component: Register,
            beforeEnter: ifNotAuthenticated
        },
        {
            path: '/forgot-password',
            component: ForgotPassword,
            beforeEnter: ifNotAuthenticated
        },
        {
            path: '/password-change/:token',
            component: PasswordReset,
            beforeEnter: ifNotAuthenticated
        },
        {
            path: '/login',
            component: Login,
            beforeEnter: ifNotAuthenticated
        },
        {
            path: '/page-not-found',
            component: PageNotFound,
        },

        {
            path: '/home',
            component: Home,
            beforeEnter: ifAuthenticated,
            children: [
                {
                    path: '',
                    component: IndexLoggedIn,
                },
                {
                    path: 'event-attendance',
                    component: EventAttendance,
                },
                {
                    path: 'events',
                    component: Events,
                },
                {
                    path: 'personal-data',
                    component: PersonalData,
                },
                {
                    path: 'annual-accreditation',
                    component: AnnualAccreditation,
                },
                {
                    path: 'annual-accreditation/request/:id/:aaid?',
                    component: AnnualAccreditationRequest,
                },
            ]
        },
        {
            path: '/*',
            redirect: '/page-not-found'
        },
    ],
};

export default routes;

function setAuthTokenToAxiosHeader(token) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
}


