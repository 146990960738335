<template>
    <div>
        <h1>{{ $t('registration.register-heading') }}</h1>
        <span>{{ $t('registration.register-heading-msg') }}</span>
        <hr>

        <div class="form-section register">
            <b-form ref="form" autocomplete="off" @submit.prevent="onSubmit">

                <h5>{{ $t('registration.portal-info') }}</h5>
                <hr>

                <div class="row">
                    <div class="col-md-6">
                        <div class="row mb-3">
                            <div class="col-md-3">
                                <label class="required-label">{{ $t('basics.mail') }}</label>
                            </div>
                            <div class="col-md-9">
                                <b-form-input
                                    v-model="formdata['EMail']"
                                    required
                                    size="sm"
                                    type="email">
                                </b-form-input>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="row mb-3">
                            <div class="col-md-3">
                                <label class="required-label">{{ $t('basics.password') }}</label>
                                <button v-b-tooltip.hover.righttop :title="$t('registration.password-requirements')" class="tooltip-info tooltip-button"
                                        type="button">
                                    <i class="fa fa-info-circle"></i>
                                </button>
                            </div>

                            <div class="col-md-9">
                                <b-form-input
                                    v-model="formdata['Passwd']"
                                    required
                                    size="sm"
                                    type="password">
                                </b-form-input>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row mb-3">
                            <div class="col-md-3">
                                <label class="required-label">{{ $t('basics.password-confirm') }}</label>
                            </div>

                            <div class="col-md-9">
                                <b-form-input
                                    v-model="formdata['Passwd_confirm']"
                                    required
                                    size="sm"
                                    type="password">
                                </b-form-input>
                            </div>
                        </div>
                    </div>
                </div>

                <br>

                <h5>{{ $t('registration.personal-info') }}</h5>
                <hr>
                <div class="row">
                    <div class="col-md-6">
                        <div class="row mb-3">
                            <div class="col-md-3">
                                <label class="required-label">{{ $t('basics.salutation') }}</label>
                            </div>
                            <div class="col-md-9">
                                <b-form-select v-model="formdata['Prefix']" :options="relData.Salutations"
                                               :text-field="$i18n.locale=='en' ? 'SysValue_EN' : 'SysValue_FR'"
                                               required
                                               size="sm"
                                               value-field="SysInfoID">
                                    <template #first>
                                        <b-form-select-option :value="null"></b-form-select-option>
                                    </template>
                                </b-form-select>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="row mb-3">
                            <div class="col-md-3">
                                <label class="required-label">{{ $t('basics.firstname') }}</label>
                            </div>
                            <div class="col-md-9">
                                <b-form-input
                                    v-model="formdata['Firstname']"
                                    required
                                    size="sm"
                                    type="text">
                                </b-form-input>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row mb-3">
                            <div class="col-md-3">
                                <label class="required-label">{{ $t('basics.lastname') }}</label>
                            </div>
                            <div class="col-md-9">
                                <b-form-input
                                    v-model="formdata['Lastname']"
                                    required
                                    size="sm"
                                    type="text">
                                </b-form-input>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="row mb-3">
                            <div class="col-md-3">
                                <label class="required-label">{{ $t('registration.date-of-birth') }}</label>
                            </div>
                            <div class="col-md-9">
                                <date-time-picker
                                    :field="'DateOfBirth'"
                                    :formdata="formdata"
                                    :required="true">
                                </date-time-picker>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row mb-3">
                            <div class="col-md-3">
                                <label class="required-label">{{ $t('registration.place-of-birth') }}</label>
                            </div>
                            <div class="col-md-9">
                                <b-form-input
                                    v-model="formdata['PlaceOfBirth']"
                                    required
                                    size="sm"
                                    type="text">
                                </b-form-input>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="row mb-3">
                            <div class="col-md-3">
                                <label class="required-label">{{ $t('registration.country-of-birth') }}</label>
                            </div>
                            <div class="col-md-9">
                                <b-form-select v-model="formdata['NativeCountryID']" :options="relData.Countries"
                                               :text-field="$i18n.locale=='en' ? 'CountryName_EN' : 'CountryName_FR'"
                                               required
                                               size="sm"
                                               value-field="CountryID">
                                    <template #first>
                                        <b-form-select-option :value="null"></b-form-select-option>
                                    </template>
                                </b-form-select>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row mb-3">
                            <div class="col-md-3">
                                <label class="required-label">{{ $t('basics.nationality') }}</label>
                            </div>
                            <div class="col-md-9">
                                <b-form-select v-model="formdata['Nationality']" :options="relData.Countries"
                                               :text-field="$i18n.locale=='en' ? 'CountryName_EN' : 'CountryName_FR'"
                                               required
                                               size="sm"
                                               value-field="CountryID">
                                    <template #first>
                                        <b-form-select-option :value="null"></b-form-select-option>
                                    </template>
                                </b-form-select>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="row mb-3">
                            <div class="col-md-3">
                                <label class="required-label">{{ $t('registration.photo') }}</label>
                                <button v-b-tooltip.hover.righttop :title="$t('registration.photo-info-msg')" class="tooltip-info tooltip-button"
                                        type="button">
                                    <i class="fa fa-info-circle"></i>
                                </button>
                                <div>
                                    <span class="file-info-label">[Max. {{ maxFileSize }}MB / ' jpg ']</span>
                                </div>
                            </div>
                            <div class="col-md-9">
                                <file-handler
                                    :formdata="formdata"
                                    :required="true"
                                    :showButtons="true"
                                    :showDelete="false"
                                    :showDownload="false"
                                    :showImage="false"
                                    acceptTypes=".jpg, .jpeg"
                                    eventId="0"
                                    field="Photo"
                                    idField="MasterdataID"
                                    placeholder="fa-user-circle"
                                    upload_endpoint="/registration/document-upload"
                                >
                                </file-handler>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">

                    </div>
                </div>

                <br>

                <h5>{{ $t('registration.address-info') }}</h5>
                <span style="font-size: 11px">{{ $t('registration.address-info-msg') }}</span>
                <hr>
                <div class="row">
                    <div class="col-md-6">
                        <div class="row mb-3">
                            <div class="col-md-3">
                                <label class="required-label">{{ $t('address.street') }}</label>
                            </div>
                            <div class="col-md-9">
                                <b-form-input
                                    v-model="formdata['Street']"
                                    required
                                    size="sm"
                                    type="text">
                                </b-form-input>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row mb-3">
                            <div class="col-md-3">
                                <label class="required-label">{{ $t('address.house-number') }}</label>
                            </div>
                            <div class="col-md-9">
                                <b-form-input
                                    v-model="formdata['HouseNumber']"
                                    required
                                    size="sm"
                                    type="text">
                                </b-form-input>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="row mb-3">
                            <div class="col-md-3">
                                <label class="required-label">{{ $t('address.zip') }}</label>
                            </div>
                            <div class="col-md-9">
                                <b-form-input
                                    v-model="formdata['ZIP']"
                                    required
                                    size="sm"
                                    type="text">
                                </b-form-input>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row mb-3">
                            <div class="col-md-3">
                                <label class="required-label">{{ $t('address.city') }}</label>
                            </div>
                            <div class="col-md-9">
                                <b-form-input
                                    v-model="formdata['City']"
                                    required
                                    size="sm"
                                    type="text">
                                </b-form-input>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="row mb-3">
                            <div class="col-md-3">
                                <label class="required-label">{{ $t('registration.residence-country') }}</label>
                            </div>
                            <div class="col-md-9">
                                <b-form-select v-model="formdata['CountryID']" :options="relData.Countries"
                                               :text-field="$i18n.locale=='en' ? 'CountryName_EN' : 'CountryName_FR'"
                                               required
                                               size="sm"
                                               value-field="CountryID">
                                    <template #first>
                                        <b-form-select-option :value="null"></b-form-select-option>
                                    </template>
                                </b-form-select>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">

                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="row mb-3">
                            <div class="col-md-3">
                                <label class="required-label">{{ $t('phone.telephone') }}</label>
                            </div>
                            <div class="col-md-9">
                                <b-form-input
                                    v-model="formdata['Telephone']"
                                    required
                                    size="sm"
                                    type="text">
                                </b-form-input>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row mb-3">
                            <div class="col-md-3">
                                <label>{{ $t('phone.mobile') }}</label>
                            </div>
                            <div class="col-md-9">
                                <b-form-input
                                    v-model="formdata['Mobil']"
                                    size="sm"
                                    type="text">
                                </b-form-input>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="row mb-3">
                            <div class="col-md-3">
                                <label>{{ $t('phone.fax') }}</label>
                            </div>
                            <div class="col-md-9">
                                <b-form-input
                                    v-model="formdata['Fax']"
                                    size="sm"
                                    type="text">
                                </b-form-input>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row mb-3">
                            <div class="col-md-3">
                                <label>Twitter</label>
                            </div>
                            <div class="col-md-9">
                                <b-form-input
                                    v-model="formdata['Twitter']"
                                    size="sm"
                                    type="text">
                                </b-form-input>
                            </div>
                        </div>
                    </div>
                </div>

                <br>

                <h5>{{ $t('registration.official-info') }}</h5>
                <hr>

                <div class="row">
                    <div class="col-md-6">
                        <div class="row mb-3">
                            <div class="col-md-3">
                                <label class="required-label">{{ $t('registration.identification') }}</label>
                            </div>
                            <div class="col-md-9">
                                <b-form-select v-model="formdata['Identification']" :options="relData.Identifications"
                                               :text-field="$i18n.locale=='en' ? 'SysValue_EN' : 'SysValue_FR'"
                                               required
                                               size="sm"
                                               value-field="SysInfoID">
                                    <template #first>
                                        <b-form-select-option :value="null"></b-form-select-option>
                                    </template>
                                </b-form-select>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">

                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="row mb-3">
                            <div class="col-md-3">
                                <label class="required-label">{{ $t('registration.identity-number') }}</label>
                            </div>
                            <div class="col-md-9">
                                <b-form-input
                                    v-model="formdata['IdentityNumber']"
                                    required
                                    size="sm"
                                    type="text">
                                </b-form-input>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row mb-3">
                            <div class="col-md-3">
                                <label class="required-label">{{ $t('registration.issued-by') }}</label>
                            </div>
                            <div class="col-md-9">
                                <b-form-input
                                    v-model="formdata['IdentityPlaceOfIssue']"
                                    required
                                    size="sm"
                                    type="text">
                                </b-form-input>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="row mb-3">
                            <div class="col-md-3">
                                <label class="required-label">{{
                                        $t('registration.national-press-card-number')
                                    }}</label>
                            </div>
                            <div class="col-md-9">
                                <b-form-input
                                    v-model="formdata['NationalPressCardNumber']"
                                    required
                                    size="sm"
                                    type="text">
                                </b-form-input>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row mb-3">
                            <div class="col-md-3">
                                <label class="required-label">{{ $t('registration.issued-by') }}</label>
                            </div>
                            <div class="col-md-9">
                                <b-form-input
                                    v-model="formdata['NationalPressCardIssuedBy']"
                                    required
                                    size="sm"
                                    type="text">
                                </b-form-input>
                            </div>
                        </div>
                    </div>
                </div>

                <br>

                <h5>{{ $t('registration.media-info') }}</h5>
                <hr>

                <div class="row">
                    <div class="col-md-6">
                        <div class="row mb-3">
                            <div class="col-md-3">
                                <label class="required-label">{{ $t('registration.type-of-media') }}</label>
                            </div>
                            <div class="col-md-9">
                                <b-form-select v-model="formdata['TypeOfMedia']" :options="relData.TypeOfMedias"
                                               :text-field="$i18n.locale=='en' ? 'SysValue_EN' : 'SysValue_FR'"
                                               required
                                               size="sm"
                                               value-field="SysInfoID">
                                    <template #first>
                                        <b-form-select-option :value="null"></b-form-select-option>
                                    </template>
                                </b-form-select>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div v-if="typeOfMediaOtherSelected()" class="row mb-3">
                            <div class="col-md-3">
                                <label class="required-label">{{ $t('registration.type-of-media-other') }}</label>
                            </div>
                            <div class="col-md-9">
                                <b-form-input
                                    v-model="formdata['TypeOfMediaOther']"
                                    :required="typeOfMediaOtherSelected()"
                                    size="sm"
                                    type="text">
                                </b-form-input>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="row mb-3">
                            <div class="col-md-3">
                                <label class="required-label">{{ $t('registration.organization') }}</label>
                            </div>
                            <div class="col-md-9">
                                <b-form-input
                                    v-model="formdata['Organization']"
                                    required
                                    size="sm"
                                    type="text">
                                </b-form-input>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="row mb-3">
                            <div class="col-md-3">
                                <label class="required-label">{{ $t('registration.qualification') }}</label>
                            </div>
                            <div class="col-md-9">
                                <b-form-select v-model="formdata['Qualification']" :options="relData.Qualifications"
                                               :text-field="$i18n.locale=='en' ? 'SysValue_EN' : 'SysValue_FR'"
                                               required
                                               size="sm"
                                               value-field="SysInfoID">
                                    <template #first>
                                        <b-form-select-option :value="null"></b-form-select-option>
                                    </template>
                                </b-form-select>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row mb-3">
                            <div class="col-md-3">
                                <label class="required-label">{{ $t('registration.nationality-of-media-org') }}</label>
                            </div>
                            <div class="col-md-9">
                                <b-form-select v-model="formdata['OrganizationCountryID']" :options="relData.Countries"
                                               :text-field="$i18n.locale=='en' ? 'CountryName_EN' : 'CountryName_FR'"
                                               required
                                               size="sm"
                                               value-field="CountryID">
                                    <template #first>
                                        <b-form-select-option :value="null"></b-form-select-option>
                                    </template>
                                </b-form-select>
                            </div>
                        </div>
                    </div>
                </div>

                <hr>

                <div class="row">
                    <div class="col-md-6">

                        <div class="row mb-3">
                            <div class="col-md-3">
                                <label class="required-label">Captcha</label>
                            </div>

                            <div class="col-md-9">

                                <div class="captcha-section">
                                    <div class="captcha" v-html="captcha"></div>
                                </div>

                                <b-form-input
                                    v-model="formdata['captcha']"
                                    :placeholder="$t('registration.enter-captcha') + '...'"
                                    required
                                    size="sm"
                                    type="text">
                                </b-form-input>

                            </div>
                        </div>
                        <!--                        <b-button class="nato-default-btn" type="button" @click="reloadCaptcha"-->
                        <!--                                  :title="$t('registration.reload-captcha')" v-b-tooltip:hover>-->
                        <!--                            <i class="fas fa-redo"></i>-->
                        <!--                        </b-button>-->
                    </div>

                    <div class="col-md-6">
                        <b-form-checkbox
                            name="accept_data"
                            required
                            switch
                        >
                            <label class="required-label">{{ $t('registration.agree-stored-data') }}</label>
                        </b-form-checkbox>
                    </div>


                </div>

                <hr>

                <div style="text-align: right">
                    <b-button ref="submit" class="nato-default-btn" size="lg" type="submit" value="submit">
                        {{ $t('registration.register') }}
                    </b-button>
                </div>

            </b-form>
        </div>
    </div>
</template>

<script>
export default {
    name: "Register",
    data() {
        return {
            relData: {},
            formdata: {},
            maxFileSize: 9,
            captcha: null
        }
    },
    created() {
        this.initView();
        this.maxFileSize = get_MIX_REGISTRATION_MAX_FILESIZE();
    },
    methods: {
        initView() {
            this.showLoadingApp(true);
            axios.get('/registration/getrelationaldata').then((response) => {
                this.relData = response.data.data;
                this.sortCountries(this.relData.Countries)
                this.reloadCaptcha();
            });
        },
        onSubmit(event) {
            if (this.validate()) {
                this.showLoadingApp(true);
                this.formdata['language'] = this.$i18n.locale;
                axios.post('/registration/register', this.formdata).then(response => {
                    this.onSaveSuccess(response);
                }).catch(error => {
                    this.onSaveError(error);
                });
            }
        },
        validate() {
            if (!this.checkPassword()) {
                this.showErrorToast(this.$t('registration.passwords-not-equal'), 5000);
                return false;
            }
            // if (this.formdata['Photo'] == null) {
            //     this.showErrorToast(this.$t('filehandler.error-fileupload'), 5000);
            //     return false;
            // }

            return true;
        },
        onSaveSuccess(response) {
            this.showLoadingApp(false);
            this.showSuccessToast(this.$t('registration.successfully-registered'))

            setTimeout(() => {
                this.$router.push('/login');
            }, 2000);
        },
        onSaveError(error) {
            this.showLoadingApp(false);

            let errors = error.response.data.error.errors;
            if (errors != null) {
                if (errors.EMail && errors.EMail[0] == 'validation.unique') {
                    this.showErrorToast(this.$t('registration.error-email'), 5000);
                    return;
                }
                if (errors.Passwd) {
                    this.showErrorToast(this.$t('registration.error-password'), 5000);
                    return;
                }
                if (errors.captcha) {
                    this.reloadCaptcha();
                    this.showErrorToast(this.$t('registration.error-captcha'), 5000);
                    return;
                }
            }

            this.showErrorToast(this.$t('basics.fail-saved'), 5000);
        },
        checkPassword() {
            if (this.formdata != null && this.formdata['Passwd_confirm'] === this.formdata['Passwd']) {
                return true;
            }

            return false;
        },
        typeOfMediaOtherSelected() {
            if (this.formdata != null && this.formdata['TypeOfMedia'] == 0) {
                return true;
            } else {
                return false;
            }
        },
        reloadCaptcha() {
            axios.get('captcha/image_tag').then(response => {
                this.captcha = response.data;
                this.showLoadingApp(false);
            });
        },
    }
}
</script>

<style scoped>

.register {
    padding-bottom: 150px;
}

h5 {
    font-weight: bold;
}

.file-info-label {
    font-size: 12px;
}

.captcha {
    /*border: 1px solid rgba(0, 0, 0, 0.1);*/
}

.captcha-section {
    text-align: center;
    margin-bottom: 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

</style>
